<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-2">
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t('globalDriverFee.numPackageFrom')
                    }}</label>
                    <input
                        v-model.number="model.num_package_from"
                        type="number"
                        step="any"
                        class="form-control"
                        :placeholder="$t('globalDriverFee.numPackageFrom')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('num_package_from')"
                    >
                        {{ errors.first('num_package_from') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t('globalDriverFee.numPackageTo')
                    }}</label>
                    <input
                        v-model.number="model.num_package_to"
                        type="number"
                        step="any"
                        class="form-control"
                        :placeholder="$t('globalDriverFee.numPackageTo')"
                    />
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('num_package_to')"
                    >
                        {{ errors.first('num_package_to') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t('globalDriverFee.expressVipDeliveryFee')
                    }}</label>
                    <div class="tw-flex">
                        <input
                            type="number"
                            class="tw-border tw-border-r-0 tw-rounded-r-none tw-rounded tw-py-2 tw-px-3 tw-outline-none tw-w-full"
                            :placeholder="
                                $t('globalDriverFee.expressVipDeliveryFee')
                            "
                            v-model.number="model.express_vip_delivery_fee"
                        />
                        <select
                            v-model="model.express_vip_delivery_fee_type"
                            class="tw-w-12 tw-border tw-border-l-0 tw-rounded-r tw-outline-none"
                        >
                            <option value="$">$</option>
                            <option value="%">%</option>
                        </select>
                    </div>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('express_vip_delivery_fee')"
                    >
                        {{ errors.first('express_vip_delivery_fee') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t('globalDriverFee.expressNormalDeliveryFee')
                    }}</label>
                    <div class="tw-flex">
                        <input
                            type="number"
                            class="tw-border tw-border-r-0 tw-rounded-r-none tw-rounded tw-py-2 tw-px-3 tw-outline-none tw-w-full"
                            :placeholder="
                                $t('globalDriverFee.expressNormalDeliveryFee')
                            "
                            v-model.number="model.express_normal_delivery_fee"
                        />
                        <select
                            v-model="model.express_normal_delivery_fee_type"
                            class="tw-w-12 tw-border tw-border-l-0 tw-rounded-r tw-outline-none"
                        >
                            <option value="$">$</option>
                            <option value="%">%</option>
                        </select>
                    </div>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('express_normal_delivery_fee')"
                    >
                        {{ errors.first('express_normal_delivery_fee') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t('globalDriverFee.standardVipDeliveryFee')
                    }}</label>
                    <div class="tw-flex">
                        <input
                            type="number"
                            class="tw-border tw-border-r-0 tw-rounded-r-none tw-rounded tw-py-2 tw-px-3 tw-outline-none tw-w-full"
                            :placeholder="
                                $t('globalDriverFee.standardVipDeliveryFee')
                            "
                            v-model.number="model.standard_vip_delivery_fee"
                        />
                        <select
                            v-model="model.standard_vip_delivery_fee_type"
                            class="tw-w-12 tw-border tw-border-l-0 tw-rounded-r tw-outline-none"
                        >
                            <option value="$">$</option>
                            <option value="%">%</option>
                        </select>
                    </div>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('standard_vip_delivery_fee')"
                    >
                        {{ errors.first('standard_vip_delivery_fee') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{
                        $t('globalDriverFee.standardNormalDeliveryFee')
                    }}</label>
                    <div class="tw-flex">
                        <input
                            type="number"
                            class="tw-border tw-border-r-0 tw-rounded-r-none tw-rounded tw-py-2 tw-px-3 tw-outline-none tw-w-full"
                            :placeholder="
                                $t('globalDriverFee.standardNormalDeliveryFee')
                            "
                            v-model.number="model.standard_normal_delivery_fee"
                        />
                        <select
                            v-model="model.standard_normal_delivery_fee_type"
                            class="tw-w-12 tw-border tw-border-l-0 tw-rounded-r tw-outline-none"
                        >
                            <option value="$">$</option>
                            <option value="%">%</option>
                        </select>
                    </div>
                    <div
                        class="tw-text-red-600 tw-text-xs"
                        v-if="errors.has('standard_normal_delivery_fee')"
                    >
                        {{ errors.first('standard_normal_delivery_fee') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button
                        @click.prevent="$emit('cancel')"
                        :disabled="loading"
                        >{{ $t('cancel') }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        outline
                        @click.prevent="onSaveAddNew"
                        :disabled="loading"
                        >{{ $t('saveAddNew') }}</ts-button
                    >
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        @click.prevent="onSave"
                        :disabled="loading"
                        >{{ $t('save') }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        :disabled="loading"
                        @click.prevent="onUpdate"
                        >{{ $t('update') }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { isEmpty } from 'lodash'
import { Errors } from 'form-backend-validation'
import { mapActions, mapState } from 'vuex'

export default {
    name: 'globalDriverFee',
    data () {
        return {
            loading: false,
            errors: new Errors(),
            model: {
                id: 0,
                service_type_id: null,
                num_package_from: null,
                num_package_to: null,
                express_vip_delivery_fee: null,
                express_vip_delivery_fee_type: null,
                express_normal_delivery_fee: null,
                express_normal_delivery_fee_type: null,
                standard_vip_delivery_fee: null,
                standard_vip_delivery_fee_type: null,
                standard_normal_delivery_fee: null,
                standard_normal_delivery_fee_type: null
            }
        }
    },
    computed: {
        ...mapState('finance/globalDriverFee', ['edit_data']),
        isUpdate () {
            return !isEmpty(this.edit_data)
        }
    },
    created () {
        this.setEditData()
    },
    methods: {
        ...mapActions('finance/globalDriverFee', ['update']),
        onUpdate () {
            this.loading = true
            this.update({
                id: this.model.id,
                data: this.model
            })
                .then(response => {
                    this.$notify({ type: 'success', text: response.message })
                    this.$emit('cancel')
                })
                .catch(error => {
                    this.$notify({ type: 'error', text: error.message })
                    this.errors = new Errors(error.errors)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        clearInput () {
            this.model.id = 0
            this.model.service_type_id = null
            this.model.num_package_from = null
            this.model.num_package_to = null
            this.model.express_vip_delivery_fee = null
            this.model.express_vip_delivery_fee_type = null
            this.model.express_normal_delivery_fee = null
            this.model.express_normal_delivery_fee_type = null
            this.model.standard_vip_delivery_fee = null
            this.model.standard_vip_delivery_fee_type = null
            this.model.standard_normal_delivery_fee = null
            this.model.standard_normal_delivery_fee_type = null
        },
        async setEditData () {
            this.loading = true
            if (!isEmpty(this.edit_data)) {
                this.model.id = this.edit_data.id
                this.model.service_type_id = this.edit_data.service_type_id
                this.model.num_package_from = this.edit_data.num_package_from
                this.model.num_package_to = this.edit_data.num_package_to
                this.model.express_vip_delivery_fee =
                    this.edit_data.express_vip_delivery_fee
                this.model.express_vip_delivery_fee_type =
                    this.edit_data.express_vip_delivery_fee_type
                this.model.express_normal_delivery_fee =
                    this.edit_data.express_normal_delivery_fee
                this.model.express_normal_delivery_fee_type =
                    this.edit_data.express_normal_delivery_fee_type
                this.model.standard_vip_delivery_fee =
                    this.edit_data.standard_vip_delivery_fee
                this.model.standard_vip_delivery_fee_type =
                    this.edit_data.standard_vip_delivery_fee_type
                this.model.standard_normal_delivery_fee =
                    this.edit_data.standard_normal_delivery_fee
                this.model.standard_normal_delivery_fee_type =
                    this.edit_data.standard_normal_delivery_fee_type
            }
            this.loading = false
        }
    }
}
</script>
